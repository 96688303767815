/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {
  createTheme,
  SxProps as OriginalSxProps,
  PaletteColor,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles'

import env from '@core/env'

import {
  background,
  common,
  fgProgress,
  histogram,
  linkBlue,
  other,
  palette,
  states,
  suggestAnswer,
  tag,
  text,
} from './colors'

const colorPalette = {
  ...palette.blue,
  ...palette.green,
  ...palette.neutral,
  ...palette.purple,
  ...palette.red,
  ...palette.teal,
  ...palette.yellow,
}
interface CustomPalette {
  tertiary: PaletteColor
  copyOption: PaletteColor
  purple: PaletteColor
  purpleLight: PaletteColor
  danger: PaletteColor
  color: typeof colorPalette
  other: typeof other
  text: typeof text
  tag: typeof tag
  background: typeof background
  suggestAnswer: typeof suggestAnswer
}

type CustomBackground = typeof background

declare module '@mui/material/styles' {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
  interface TypeBackground extends CustomBackground {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
    copyOption: true
    purple: true
    purpleLight: true
    danger: true
    successLight: true
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    tertiary: true
    copyOption: true
    purple: true
    purpleLight: true
    danger: true
    successLight: true
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true
    copyOption: true
    purple: true
    purpleLight: true
    danger: true
    successLight: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true
    copyOption: true
    purple: true
    purpleLight: true
    danger: true
    successLight: true
  }
}

declare module '@mui/material/Dialog' {
  interface DialogProps {
    'data-full-page-width'?: boolean
    'data-full-page-height'?: boolean
  }
}

const defaultTheme = createTheme()

function customScroll(selector: string) {
  return {
    [`${selector}::-webkit-scrollbar`]: {
      width: 10,
      height: 10,
      background: 'rgba(0, 0, 0, 0.1)',
      borderRadius: 4,
    },
    [`${selector}::-webkit-scrollbar-thumb`]: {
      width: 10,
      background: 'rgba(0, 0, 0, 0.4)',
      height: 10,
      borderRadius: 4,
    },
  }
}

const tagColors = {}

for (const [name, value] of Object.entries(tag)) {
  tagColors[name] = defaultTheme.palette.augmentColor({
    name,
    color: {
      // @ts-ignore
      main: value.main,
      contrastText: value.contrastText,
    },
  })
}

export const theme = createTheme({
  palette: {
    primary: { main: text.slateBlue, contrastText: common.white },
    secondary: {
      main: fgProgress,
      contrastText: common.white,
    },
    purple: defaultTheme.palette.augmentColor({
      color: { main: background.purple },
      name: 'purple',
    }),
    tertiary: defaultTheme.palette.augmentColor({
      color: {
        main: '#f5f7f8',
        dark: '#ecedf0',
        contrastText: '#42526e',
      },
      name: 'tertiary',
    }),
    copyOption: defaultTheme.palette.augmentColor({
      color: {
        main: '#DDEBFF',
        dark: '#DDEBFF',
        contrastText: '#2685FF',
      },
      name: 'copyOption',
    }),
    success: defaultTheme.palette.augmentColor({
      color: {
        main: states.success,
      },
      name: 'success',
    }),
    successLight: defaultTheme.palette.augmentColor({
      color: {
        main: '#e4fbec',
        contrastText: '#42526e',
      },
      name: 'successLight',
    }),
    error: defaultTheme.palette.augmentColor({
      color: {
        main: states.error,
      },
      name: 'error',
    }),
    warning: defaultTheme.palette.augmentColor({
      color: {
        main: states.warning,
      },
      name: 'warning',
    }),
    danger: defaultTheme.palette.augmentColor({
      color: {
        // @ts-ignore
        main: palette.red.R050,
        dark: palette.red.R075,
        contrastText: other.grayishBlueDark,
      },
    }),
    purpleLight: defaultTheme.palette.augmentColor({
      color: {
        // @ts-ignore
        main: palette.purple.P050,
        dark: palette.purple.P075,
        contrastText: other.grayishBlueDark,
      },
    }),
    background: { default: background.grey, ...background, ...states },
    // @ts-ignore
    text: { primary: text.slateBlue, secondary: text.greyBlue, ...text, ...states, linkBlue },
    // @ts-ignore
    tag: tagColors,
    histogram,
    other,
    suggestAnswer,
    color: colorPalette,
  },
  typography: {
    h1: { fontSize: '2rem', fontWeight: 500 },
    h2: { fontSize: '1.75rem', fontWeight: 500 },
    h3: { fontSize: '1.5rem', fontWeight: 500 },
    h4: { fontSize: '1.25rem', fontWeight: 500 },
    h5: { fontSize: '1rem', fontWeight: 500 },
    h6: { fontSize: '1rem', fontWeight: 500 },
    button: {
      textTransform: 'initial',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '1rem',
          lineHeight: 1.625,
          fontWeight: 400,
          letterSpacing: '-0.13px',
          ...customScroll('&'),
          ...customScroll('*'),
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '3px',
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: background.disabledButton,
          },
        },
        containedSecondary: {
          backgroundColor: linkBlue,
        },
        outlinedSecondary: {
          borderColor: linkBlue,
          color: linkBlue,
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'tertiary' },
          style: {
            '&.Mui-focusVisible': {
              outline: `2px solid ${palette.blue.B200}`,
              background: palette.neutral.N040,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'danger' },
          style: {
            '&.Mui-focusVisible': {
              outline: `2px solid ${palette.red.R200}`,
              background: palette.red.R075,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'purpleLight' },
          style: {
            '&.Mui-focusVisible': {
              outline: `2px solid ${palette.blue.B200}`,
              background: palette.purple.P100,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'successLight' },
          style: {
            '&.Mui-focusVisible': {
              outline: `2px solid ${palette.blue.B200}`,
              background: '#5ae78b',
            },
          },
        },
      ],
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorSuccess: {
          background: '#e2ffee',
          color: '#006644',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: text.slateBlue,
        },
      },
      defaultProps: {
        enterNextDelay: 500,
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(0, 3, 3, 0),
        },
      },
    },
    MuiDialog: {
      variants: [
        {
          props: { 'data-full-page-width': true },
          style: {
            '& .MuiDialog-paper': {
              maxWidth: 'calc(1280px - 64px)',
              width: '100%',
            },
          },
        },
        {
          props: { 'data-full-page-height': true },
          style: {
            '& .MuiDialog-paper': {
              maxHeight: 'calc(100vh - 64px)',
              height: '100%',
            },
          },
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: text.blue,
          height: 4,
          '&.Mui-disabled': {
            color: other.sliderDisabled,
            '& .MuiSlider-rail': {
              backgroundColor: other.sliderDisabled,
            },
            '& .MuiSlider-markLabel': {
              opacity: 0.5,
            },
          },
        },
        thumb: {
          height: 16,
          width: 16,
          backgroundColor: '#fff',
          border: `2px solid ${background.notification}`,
          '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
          },
          '&.Mui-disabled': {
            height: 16,
            width: 16,
            backgroundColor: other.sliderDisabled,
            border: `2px solid ${other.sliderDisabled}`,
          },
        },
        rail: { height: 4, borderRadius: 2, backgroundColor: other.ghostBlue, opacity: 1 },
        mark: { display: 'none' },
        markLabel: { fontWeight: 500, color: text.slateBlue },
        track: { height: 4, borderRadius: 2 },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: 'warning', color: 'error' },
          style: {
            color: palette.red.R500,
          },
        },
      ],
    },
  },
})

if (env.ENV === 'local') {
  // @ts-ignore
  window.theme = theme
}

declare module '@mui/material' {
  /**
   * StyleProps Usage:
   * ```
   * import { StyleProps } from '@mui/material'
   *
   * const styles = {
   *   container: {
   *     bgcolor: 'background.paper',
   *     mx: 2,
   *   }
   * } satisfies StyleProps
   * ...
   * <Box sx={styles.container}>...</Box>
   * ```
   */
  type StyleProps = Record<string, OriginalSxProps<typeof theme>>
}

export function AppTheme({
  children,
  cssReset = true,
}: {
  children: React.ReactNode
  cssReset?: boolean
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {cssReset && <CssBaseline />}
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default theme
