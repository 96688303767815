import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'

import { useApi } from '@core/api'
// eslint-disable-next-line no-restricted-imports
import type { IntegrationType, UpsertIntegrationInput } from '@core/api/generated/types.gen'
import { TextField, TextFieldProps } from '@core/components/textfield/textfield'
import { addSnack } from '@core/snack/snack-state'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export type ProBuilderIntegrationData = {
  subject_ref?: string
  centre_ref?: string
  target?: 'BILPAS' | 'PROD'
  subject_url_id: string
}

export type IntegrationInput = ProBuilderIntegrationData & {
  access_key: string
  secret_key: string
}

const defaultValues: IntegrationInput = {
  access_key: '',
  secret_key: '',
  subject_ref: '',
  centre_ref: '',
  subject_url_id: '',
  target: 'BILPAS',
}

export type IntegrationOutput = {
  id: string
  accessKey: string
  secretKey: string
  data: ProBuilderIntegrationData
}

const getDefaultValues = (integration?: IntegrationOutput) => {
  if (!integration?.id) return defaultValues

  return {
    ...integration.data,
    access_key: integration.accessKey,
    secret_key: integration.secretKey,
  }
}

const integrationKeys = {
  list: ['integrations', 'list'],
  upsert: ['integrations', 'upsert'],
  test: ['integrations', 'test'],
}

export const AdminManageIntegrations = () => {
  const form = useForm({ defaultValues })
  const { register, formState, handleSubmit } = form
  const defaultTargetValue = React.useRef(form.getValues('target'))

  const [initializedWithPassword, setInitializedWithPassword] = React.useState(false)
  const api = useApi()
  const queryClient = useQueryClient()

  const integrationQuery = useQuery({
    queryKey: integrationKeys.list,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    queryFn: async () => {
      const { data } = await api.getIntegration({
        path: {
          integration_type: 'ProBuilder',
        },
        throwOnError: true,
      })

      const values = getDefaultValues(data as unknown as IntegrationOutput)

      form.reset(values)
      defaultTargetValue.current = values.target

      setInitializedWithPassword(Boolean(values.secret_key))

      return values
    },
  })

  const upsertCustomer = useMutation({
    mutationKey: integrationKeys.upsert,
    mutationFn: async (variables: { integrationType: IntegrationType; data: IntegrationInput }) => {
      const { data } = await api.upsertIntegration({
        body: variables.data as UpsertIntegrationInput,
        path: {
          integration_type: variables.integrationType,
        },
        throwOnError: true,
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: integrationKeys.list,
      })
    },
  })

  const testConnection = useMutation({
    mutationKey: integrationKeys.test,
    mutationFn: async () => {
      const { data } = await api.testIntegration({
        path: {
          integration_type: 'ProBuilder',
        },
        throwOnError: true,
      })

      return data
    },
  })

  const handleTestConnection = async () => {
    testConnection.mutate()
  }

  const getProps = (field: keyof IntegrationInput, maxLength = 128): TextFieldProps => {
    const error = formState.errors[field]
    let message = error?.message

    if (error?.type === 'maxLength') {
      message = `Max length of ${maxLength}`
    } else if (error?.type === 'minLength') {
      message = 'Min length of 1'
    }

    const fieldProps = register(field, {
      maxLength,
      minLength: 1,
      required: 'Required',
    })

    return {
      ...fieldProps,
      disabled: formState.isSubmitting || testConnection.isPending,
      error: Boolean(error),
      helperText: message,
      autoComplete: 'off',
    }
  }

  const onSubmit = async (values: IntegrationInput) => {
    try {
      testConnection.reset()

      const response = await upsertCustomer.mutateAsync({
        integrationType: 'ProBuilder',
        data: values,
      })

      if (response.id) {
        addSnack({
          message: 'Integration updated successfully',
          severity: 'success',
        })

        return
      }

      console.error(response, 'AdminManageIntegrations.onSubmit response error')

      addSnack({
        message: 'Something went wrong',
        severity: 'warning',
      })
    } catch (error) {
      const firstError = error.detail?.[0]

      if (!firstError) {
        addSnack({ message: 'Fatal error', severity: 'error' })
        return
      }

      form.setError(firstError.loc[2], { message: firstError.msg })

      console.error(error, 'AdminManageIntegrations.onSubmit error', firstError)
    }
  }

  if (integrationQuery.isLoading) {
    return <div>loading</div>
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} name="probuilder-integration">
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          ProBuilder Integration
        </Typography>

        <TextField
          required
          {...getProps('target')}
          label="Target"
          aria-label="ProBuilder target"
          fullWidth
          select
          key={defaultTargetValue.current}
          defaultValue={defaultTargetValue.current}
        >
          <MenuItem value="BILPAS">BILPAS</MenuItem>
          <MenuItem value="PAS">PAS</MenuItem>
          <MenuItem value="PROD">PROD</MenuItem>
        </TextField>

        <Box my={2} />

        <TextField
          required
          {...getProps('access_key')}
          label="Username"
          aria-label="ProBuilder username"
          fullWidth
        />

        <Box my={2} />

        <TextField
          required
          {...getProps('secret_key', 1024)}
          label="Password"
          type="password"
          aria-label="ProBuilder password"
          fullWidth
          disabled={initializedWithPassword}
        />

        {initializedWithPassword && (
          <Button
            size="small"
            sx={{ mt: 1 }}
            disabled={testConnection.isPending}
            onClick={() => {
              setInitializedWithPassword(false)
              form.setValue('secret_key', '')
              testConnection.reset()
              setTimeout(() => {
                form.setFocus('secret_key')
              }, 100)
            }}
          >
            Clear Password
          </Button>
        )}

        <Divider sx={{ my: 4 }} />

        <TextField
          required
          {...getProps('centre_ref')}
          label="Centre Reference"
          aria-label="ProBuilder centre_ref"
          fullWidth
        />

        <Box mt={2} />
        <TextField
          required
          {...getProps('subject_ref')}
          label="Subject Reference"
          aria-label="ProBuilder subject_ref"
          fullWidth
        />

        <Box mt={2} />
        <TextField
          required
          {...getProps('subject_url_id')}
          label="Subject URL ID"
          aria-label="ProBuilder subject_url_id"
          helperText="ID used to build the destination URL"
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        {initializedWithPassword && (
          <LoadingButton
            onClick={handleTestConnection}
            variant="contained"
            loading={testConnection.isPending}
            disabled={formState.isSubmitting || formState.isDirty}
          >
            Test Integration
          </LoadingButton>
        )}

        <LoadingButton
          variant="contained"
          disabled={formState.isSubmitting || !formState.isDirty}
          loading={formState.isSubmitting}
          type="submit"
        >
          Update
        </LoadingButton>
      </DialogActions>

      {testConnection.data && (
        <Box my={1} px={4}>
          <Divider sx={{ my: 4 }} />
          <TextField
            label="Connection Result"
            value={JSON.stringify(testConnection.data.result, null, 2)}
            maxRows={16}
            multiline
            onChange={() => {}}
            fullWidth
            spellCheck={false}
          />
        </Box>
      )}
    </Box>
  )
}
