import { immer } from 'zustand/middleware/immer'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export type Batch = {
  id: string
  createdAt: string
  itemsRequired: number
  aiModel: {
    id: string
    name: string
    type: string
  }
  selectedSubmodels: string[]
  firstDone: { id: string }[]
}

type QualityMetric = { name: string; value: number }

export type ContentPart = {
  notes?: string
  sample_response?: string
  stem: string
}

export type ContentVersion = {
  createdAt: string
  parts?: ContentPart[]
  qualityMetrics?: QualityMetric[]
  question: string
  stimulus?: string
}

export type Item = {
  id: string
  createdAt: string
  projectId: string | null
  isArchived: boolean
  step: string
  status: string
  identifier: string
  contentType?: string
  clonedFromId: string | null
  currentContentVersion: ContentVersion[]
  savedContentVersion: ContentVersion[]
}

export type BatchDetails = Omit<Batch, 'firstDone'> & {
  itemsFailed: number
  itemsPassed: number
  itemsRequired: number
  aiModel: {
    id: string
    name: string
    minTemperature: number | null
    maxTemperature: number | null
  }
  items: Item[]
}

export type State = {
  loadingList: boolean
  batchList: Record<string, Batch>
  selectedDiscard: string[]
  selectedBatchId: string | null
}

type Actions = {
  createTempBatch: (batch: Batch) => void
  reset: () => void
}

const initialState: State = {
  selectedBatchId: null,
  loadingList: false,
  batchList: {},
  selectedDiscard: [],
}

export const useBatches = createWithEqualityFn(
  immer<State & Actions>((set) => {
    return {
      ...initialState,

      createTempBatch(batch) {
        set((prev) => {
          const { id } = batch

          prev.selectedBatchId = id

          if (!id || id in prev.batchList) {
            return
          }

          prev.batchList[id] = batch
        })
      },

      reset() {
        set(initialState)
      },
    }
  }),
  shallow,
)
