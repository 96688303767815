import { useApi } from '@core/api'
import { getClient } from '@core/api/api-state'
import { ITEM_SET2 } from '@core/constants/content-type'
import { getUserRole } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { ItemStatus, ItemStatusMap } from '@core/types'
import { useItemWatchStatus } from '@core/watch-item-status/watch-item-status-state'
import { fetchItemContent } from '@pages/author/author-content-manager'
import { useMutation } from '@tanstack/react-query'

import * as queries from './content-version-queries'
import { useContentVersionState, type Slice } from './content-version-state' // only types allowed!
import type { ItemActions } from './content-version-types'

export const notifyPossibleKeys = (options: { possible?: string }[]) => {
  const hasPossibleKeys = options?.find((answer) => answer?.possible?.toUpperCase() === 'KEY')

  if (!hasPossibleKeys) {
    addSnack({
      message: 'A highly probable key was not found. Edit or regenerate options and try again.',
      severity: 'info',
      checkboxKey: 'hidePossibleKeysNotFoundInfo',
    })
  }
}

type UpdateItemStatusArgs = {
  rootId: string
  itemId: string
  updatedAt?: string
  status: ItemStatus
  override?: boolean
}

export type GenerateRationalesInput = {
  itemKey: string
  keysOnly: boolean
  option?: Record<string, any>
  generateRationaleFor?: number
  itemKeyCheck?: boolean
}

export type GenerateMoreItemsParams = {
  numberOfItems: string
  selectedFlavors: string[]
  selectedCreativity: number
}

export type RegenerateItemInput = {
  itemKey: string
  type: 'stem' | 'options' | 'parts' | 'part'
  removeOption?: number
}

const updateItemStatus = ({
  rootId,
  itemId,
  updatedAt = new Date().toISOString(),
  status,
  override = false,
}: UpdateItemStatusArgs) => {
  const { onUpdate, overrideItem } = useItemWatchStatus.getState()

  if (override) {
    return overrideItem(rootId, itemId, { status, updatedAt })
  }

  return onUpdate(rootId, [{ id: itemId, status, updatedAt }])
}

type CreateItemActions = (
  rootId: string,
  get: Slice['getState'],
  set: Slice['setState'],
) => ItemActions

// Common actions that can be generic enough to be used in multiple places
// @TODO: move to content-version-actions (when doing the GraphQL -> BFF migration)
export const createItemActions: CreateItemActions = (rootId, get) => ({
  async archiveItem(itemId) {
    const client = getClient()
    const { role } = getUserRole()

    try {
      const { data } = await client.mutate({
        mutation: queries.ARCHIVE_ITEM,
        variables: { itemId },
        context: { role },
      })

      if (!data?.result?.id) {
        console.warn('No archive result id returned', { itemId })

        addSnack({
          message: 'Error while deleting the item',
          severity: 'error',
        })

        return { success: false }
      }

      addSnack({
        message: 'Item deleted',
        severity: 'success',
      })

      return { success: true }
    } catch (error) {
      addSnack({
        message: 'Error while deleting the item',
        severity: 'error',
      })

      throw error
    }
  },

  async discardItemsUnsavedContent() {
    const { instances, setInstance } = get()
    let instance = instances[rootId]

    const copy = { ...instance.items }
    const itemChanges = { ...instance.itemChanges }
    const itemKeys = Object.keys(copy).sort()

    for (const itemKey of itemKeys) {
      const item = copy[itemKey]

      if (!item.savedContent) continue

      copy[itemKey] = {
        ...item,
        currentContent: item.savedContent,
      }
      itemChanges[itemKey] = Object.keys(item.savedContent).reduce((acc, key) => {
        return {
          ...acc,
          [key]: true,
        }
      }, {})
    }

    setInstance(rootId, (prev) => {
      prev.items = copy
      prev.itemChanges = itemChanges
      prev.unsavedChanges = false
      prev.unsavedChangesFromLastTime = false
      prev.rootKey += 1
    })

    instance = get().instances[rootId]

    if (instance) {
      instance.validator(instance)
      instance.updater(instance)
      instance.updater.flush()
    }
  },
})

export const itemActionKeys = {
  all: ['item-action'],
  perItem: (itemId: string) => ['item-action', itemId],
  regenerate: (itemId: string) => ['item-action', itemId, 'regenerate'],
  possibleKeys: (itemId: string) => ['item-action', itemId, 'possible-keys'],
  save: (itemId: string) => ['item-action', itemId, 'save'],
  clone: (itemId: string) => ['item-action', itemId, 'clone'],
  rationales: (itemId: string) => ['item-action', itemId, 'rationales'],
  generateMath: (itemId: string) => ['item-action', itemId, 'generate-math'],
  generateMoreItems: (itemId: string) => ['item-action', itemId, 'generate-more-items'],
  biasCheck: (itemId: string) => ['item-action', itemId, 'bias-check'],
}

export const useRegenerateItem = (rootId: string) => {
  const api = useApi()

  return useMutation({
    mutationKey: itemActionKeys.regenerate(rootId),
    mutationFn: async ({ itemKey, type, removeOption }: RegenerateItemInput) => {
      const instance = useContentVersionState.getState().instances[rootId]
      const oldStatus = instance.items[itemKey].status
      const itemId = itemKey === 'root' ? rootId : itemKey
      const { contentType } = instance.items.root

      if (contentType === ITEM_SET2) {
        updateItemStatus({ rootId, itemId, status: ItemStatusMap.IN_PROGRESS })
      }

      try {
        await instance.flush()

        const response = await api.generate({
          body: {
            itemId,
            clearStem: type === 'stem',
            clearOptions: true,
            ...(removeOption != null ? { removeOption, clearOptions: false } : {}),
          },
        })

        if (!response.data?.jobId) {
          throw new Error('No job id returned')
        }

        if (contentType !== ITEM_SET2) {
          await fetchItemContent(itemId)
        }
      } catch (error) {
        console.debug(error, error?.message)
        console.error('regenerateItem error', error?.message, { rootId, itemId, type })

        updateItemStatus({ rootId, itemId, status: oldStatus, override: true })
      }
    },
  })
}
export const useGeneratePossibleKeys = (rootId: string) => {
  const api = useApi()

  return useMutation({
    mutationKey: itemActionKeys.possibleKeys(rootId),
    mutationFn: async (itemKey: string): Promise<{ success: boolean }> => {
      const instance = useContentVersionState.getState().instances[rootId]
      const oldStatus = instance.items[itemKey].status
      const itemId = itemKey === 'root' ? rootId : itemKey

      updateItemStatus({ rootId, itemId, status: ItemStatusMap.POSSIBLE_KEYS })

      try {
        await instance.flush()
        const response = await api.possibleKeys({
          path: { item_id: itemId },
        })

        if (!response.data) {
          console.warn('Failed possible keys')
          return { success: false }
        }

        return { success: Boolean(response.data.ok) }
      } catch (error) {
        console.debug(error, error?.message)
        console.error('generatePossibleKeys error', error?.message, { rootId, itemId })

        addSnack({
          message: 'Possible key generation failed. Please try again',
          severity: 'error',
        })
        updateItemStatus({ rootId, itemId, status: oldStatus, override: true })
        return { success: false }
      }
    },
  })
}

export const useSaveItem = (rootId: string) => {
  const api = useApi()

  return useMutation({
    mutationKey: itemActionKeys.save(rootId),
    mutationFn: async (projectId: string): Promise<{ success: boolean }> => {
      const instance = useContentVersionState.getState().instances[rootId]

      await instance.flush()

      try {
        const response = await api.saveItem({
          path: { item_id: rootId },
          body: { projectId },
        })
        const isSuccess = !!response.data?.ok

        if (!isSuccess) {
          addSnack({
            message: 'An error occurred during saving.',
            severity: 'error',
          })
          return { success: false }
        }

        return { success: true }
      } catch (error) {
        console.debug(error, error?.message)
        console.error('saveItem error', error?.message, { rootId })

        addSnack({
          message: 'An error occurred during saving.',
          severity: 'error',
        })
        return { success: false }
      }
    },
  })
}

export const useCloneItem = (rootId: string) => {
  const api = useApi()

  return useMutation({
    mutationKey: itemActionKeys.clone(rootId),
    mutationFn: async (itemIdInput: string): Promise<{ newItemId: string }> => {
      const instance = useContentVersionState.getState().instances[rootId]
      const itemId = itemIdInput === 'root' ? rootId : itemIdInput

      try {
        await instance.flush()
        const response = await api.cloneItem({ path: { item_id: itemId } })

        if (!response.data?.clonedItemId) {
          throw new Error('No clone id returned')
        }

        return { newItemId: response.data.clonedItemId }
      } catch (error) {
        console.debug(error, error?.message)
        console.error('cloneItem error', error?.message, { rootId, itemId })

        addSnack({
          message: 'Item Clone failed. Please try again',
          severity: 'error',
        })

        throw error
      }
    },
  })
}

export const useGenerateRationales = (rootId: string) => {
  const api = useApi()

  return useMutation({
    mutationKey: itemActionKeys.rationales(rootId),
    mutationFn: async ({
      itemKey,
      keysOnly,
      option,
      generateRationaleFor,
      itemKeyCheck,
    }: GenerateRationalesInput) => {
      const instance = useContentVersionState.getState().instances[rootId]
      const oldStatus = instance.items[itemKey].status
      const itemId = itemKey === 'root' ? rootId : itemKey

      updateItemStatus({
        rootId,
        itemId,
        status: itemKeyCheck ? ItemStatusMap.PRETEST_KEY : ItemStatusMap.RATIONALES,
      })

      const label = itemKeyCheck ? 'key check results' : 'rationales'
      try {
        await instance.flush()

        addSnack({
          message:
            // eslint-disable-next-line max-len
            `This may take a little time to generate your ${label}! You can continue authoring your other items in the meantime. Check back soon.`,
          severity: 'info',
          checkboxKey: 'hideRationalesInfo',
        })

        const response = await api.generateRationales({
          path: { item_id: itemId },
          body: {
            keys_only: keysOnly,
            option,
            generate_rationale_for: generateRationaleFor,
            item_key_check: itemKeyCheck,
          },
        })

        if (!response.data || !response.data.ok) {
          addSnack({
            message: `Item ${label} generation failed. Please try again`,
            severity: 'error',
          })
          updateItemStatus({ rootId, itemId, status: oldStatus, override: true })
          return { success: false }
        }

        return { success: Boolean(response.data.ok) }
      } catch (error) {
        console.debug(error, error?.message)
        console.error('generateRationales error', error?.message, { rootId, itemId })

        addSnack({
          message: `Item ${label} generation failed. Please try again`,
          severity: 'error',
        })

        updateItemStatus({ rootId, itemId, status: oldStatus, override: true })

        return { success: false }
      }
    },
  })
}

export const useGenerateMoreItems = (rootId: string) => {
  const api = useApi()

  return useMutation({
    mutationKey: itemActionKeys.generateMoreItems(rootId),
    mutationFn: async ({
      numberOfItems,
      selectedFlavors,
      selectedCreativity,
    }: GenerateMoreItemsParams) => {
      try {
        const instance = useContentVersionState.getState().instances[rootId]

        await api.generateItemSet({
          body: {
            aiModelId: instance.items.root.aiModel.id,
            submodels: selectedFlavors,
            itemsRequired: parseInt(numberOfItems, 10),
            rootId,
            temperaturePercentage: selectedCreativity,
          },
        })
      } catch (error) {
        console.debug(error, error?.message)
        console.error('generateMoreItems error', error?.message, { rootId })

        addSnack({
          message: `Error while Adding more items. ${error.message}`,
          severity: 'error',
        })
      }
    },
  })
}

export const useGenerateMath = (rootId: string) => {
  const api = useApi()

  return useMutation({
    mutationKey: itemActionKeys.generateMath(rootId),
    mutationFn: async (itemId: string): Promise<{ success: boolean }> => {
      const instance = useContentVersionState.getState().instances[rootId]
      const oldStatus = instance.items.root.status

      updateItemStatus({ rootId, itemId, status: ItemStatusMap.GENERATE_MATH })

      try {
        await instance.flush()
        const response = await api.generateMath({
          path: { item_id: itemId },
        })
        if (!response.data) {
          console.warn('Failed generate math')
          return { success: false }
        }
        return { success: Boolean(response.data.ok) }
      } catch (error) {
        console.debug(error, error?.message)
        console.error('generateMath error', error?.message, { rootId, itemId })

        addSnack({
          message: 'Generate math failed. Please try again',
          severity: 'error',
        })
        updateItemStatus({ rootId, itemId, status: oldStatus, override: true })
        return { success: false }
      }
    },
  })
}

export const useRunBiasCheck = (rootId: string) => {
  const api = useApi()

  return useMutation({
    mutationKey: itemActionKeys.biasCheck(rootId),
    mutationFn: async (itemId: string): Promise<{ success: boolean }> => {
      const instance = useContentVersionState.getState().instances[rootId]
      const oldStatus = instance.items.root.status

      updateItemStatus({ rootId, itemId, status: ItemStatusMap.PRETEST_BIAS })

      try {
        await instance.updater.flush()

        const response = await api.runBiasCheck({ path: { item_id: itemId } })

        if (!response.data || !response.data.ok) {
          addSnack({
            message: 'Item bias check failed. Please try again',
            severity: 'error',
          })
          updateItemStatus({ rootId, itemId, status: oldStatus, override: true })
          return { success: false }
        }

        return { success: Boolean(response.data.ok) }
      } catch (error) {
        console.debug(error, error?.message)
        console.error('runBiasCheck error', error?.message, { rootId, itemId })

        addSnack({
          message: 'Item bias check failed. Please try again',
          severity: 'error',
        })

        updateItemStatus({ rootId, itemId, status: oldStatus, override: true })

        return { success: false }
      }
    },
  })
}
