import React from 'react'
import { Provider } from 'react-redux'
import {
  createBrowserRouter,
  isRouteErrorResponse,
  Outlet,
  RouterProvider,
  useRouteError,
} from 'react-router-dom'

import '@core/api/sentry'

import { enableMapSet } from 'immer'

import ExportItemsRoot from '@containers/export-items/export-items-root'
import ProtectedRoute, { Progress } from '@containers/main/main-route-protected'
import { UserSessionWarning } from '@containers/main/user-session-warning'
import { ApiProvider, BffClientProvider } from '@core/api'
import AuthorizedApolloProvider from '@core/api/apollo'
import Header from '@core/components/Header'
import { ScrollBarContainer } from '@core/components/ScrollBar'
import { routeMap } from '@core/constants/routes'
import { HEADER_HEIGHT } from '@core/constants/styles'
import ProjectsRoot from '@core/projects/projects-root'
import { Snacks } from '@core/snack/snack'
import { useStore } from '@core/store'
import { AppTheme } from '@core/styles/theme'
import { wrapCreateBrowserRouter } from '@sentry/react'

import Auth0Provider from './providers/auth0'

enableMapSet()

const ProviderRedux = (props: { children: React.ReactNode }) => {
  const store = useStore()

  return <Provider store={store}>{props.children}</Provider>
}

export const App = () => {
  return (
    <Auth0Provider>
      <BffClientProvider>
        <AuthorizedApolloProvider>
          <ApiProvider>
            <ProviderRedux>
              <AppTheme>
                <Snacks />
                <Outlet />
              </AppTheme>
            </ProviderRedux>
          </ApiProvider>
        </AuthorizedApolloProvider>
      </BffClientProvider>
    </Auth0Provider>
  )
}

const ErrorPage = React.lazy(() => import('@pages/404'))

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    ErrorBoundary: () => {
      const error = useRouteError()

      return isRouteErrorResponse(error) ? (
        <AppTheme>
          <React.Suspense fallback={<Progress />}>
            <ErrorPage />
          </React.Suspense>
        </AppTheme>
      ) : (
        // @ts-ignore
        <h4>{error?.message || error}</h4>
      )
    },
    children: [
      {
        index: true,
        lazy: () => import('@pages/auth/login-page'),
      },
      {
        path: routeMap.LOGIN_SSO_ROUTE,
        lazy: () => import('@pages/auth/login-page'),
      },
      {
        element: (
          <ProtectedRoute>
            <Header />
            <UserSessionWarning />
            <ExportItemsRoot />
            <ScrollBarContainer style={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
              <Outlet />
            </ScrollBarContainer>

            <ProjectsRoot />
          </ProtectedRoute>
        ),
        children: [
          {
            path: routeMap.AUTHOR_ITEM_SET,
            lazy: () => import('@pages/item-set/item-set-page'),
          },
          {
            path: routeMap.USERS_ROUTE,
            lazy: () => import('@pages/users/users-page'),
          },
          {
            path: routeMap.AUTHOR_ROUTE,
            lazy: () => import('@pages/author/author-page'),
          },
          {
            path: routeMap.AUTHOR_ITEM_ROUTE,
            lazy: () => import('@pages/author/author-page'),
          },
          {
            path: routeMap.AUTHOR_CLOZE_ROUTE,
            lazy: () => import('@pages/author-cloze/author-cloze-page'),
          },
          {
            path: routeMap.DELIVER_ROUTE,
            lazy: () => import('@pages/deliver/deliver-page'),
          },
          {
            path: routeMap.DELIVER_DRAFT,
            lazy: () => import('@pages/project-details/draft-page'),
          },
          {
            path: routeMap.DELIVER_PROJECT_ROUTE,
            lazy: () => import('@pages/project-details/project-details-page'),
          },
        ],
      },
    ],
  },
])

const AppRouter = () => {
  return <RouterProvider router={router} />
}

export default AppRouter
