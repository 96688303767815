import { useMemo, useReducer } from 'react'

import { gql, TypedDocumentNode } from '@apollo/client'
import { MCQ_STATUSES } from '@author/constants/optionsStatus'
import { useExportJobs } from '@containers/export-items/export-items-state'
import { type ErrorDetails } from '@containers/export-items/export-queries'
import { useUserRole } from '@containers/main/main-utils'
import { useApi, useSubscription } from '@core/api'
import ErrorHandler from '@core/api/ErrorHandler'
import { MCQ } from '@core/constants/content-type'
import { addSnack } from '@core/snack/snack-state'

export type Item = {
  contentType: string
  id: string
  identifier: string
  savedContent: {
    content: {
      question?: string
      passage?: string
      custom_passage?: string
      answers?: { status: string; value: string }[]
    }
  }[]
}

export type ValidationResult = {
  comptia: { value: string }
  surpass: {
    value: string
    missingKeyItems: { id: string; question: string }[]
    totalItems: number
  }
  qti: { value: string }
}

export const GET_PROJECT_ITEMS: TypedDocumentNode<{ items: Item[] }, { ids: string[] }> = gql`
  subscription project_download($ids: [uuid!]) {
    items: item(where: { is_archived: { _neq: true }, project_id: { _in: $ids } }) {
      id
      identifier
      projectId: project_id
      savedContent: content_version(args: { content_type: "saved" }) {
        content
      }
      contentType: content_type
    }
  }
`

const extension = {
  csv: 'csv',
  txt: 'txt',
  qtiv2p1: 'xml',
  comptia: 'xls',
  surpass: 'xls',
}

export type State = {
  projectIds: string[] | null
  items: Item[] | null
  open: boolean
  anchorEl: HTMLElement | null
  loading: boolean
  creatingJobFor: string
  activateSubscription: boolean
  projectName: string
  fileName?: string
}

export type Action = (Partial<State> & { type?: undefined }) | { type: 'reset'; items?: Item[] }

export const initialState: State = {
  projectIds: null,
  items: null,
  anchorEl: null,
  loading: false,
  open: false,
  activateSubscription: false,
  creatingJobFor: '',
  projectName: '',
  fileName: '',
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'reset':
      return { ...initialState, items: action.items || null }

    default:
      return { ...state, ...action }
  }
}

export const useDownloadItems = () => {
  const api = useApi()
  const { role } = useUserRole()
  const [state, setState] = useReducer(reducer, initialState)

  useSubscription(GET_PROJECT_ITEMS, {
    context: { role },
    variables: { ids: state.projectIds as string[] },
    skip: !state.activateSubscription || !state.projectIds,
    onData: ({ data }) => {
      setState({
        loading: false,
        items: data.data?.items,
      })
    },
  })

  return useMemo(
    () => ({
      state,
      setState,

      onCancel() {
        setState({ type: 'reset' })
      },

      onClose() {
        setState({ open: false, activateSubscription: false })
      },

      onOpen(
        projectId: string | string[],
        projectName: string,
        anchorEl: HTMLElement,
        items?: Item[],
      ) {
        setState({
          items,
          loading: !items,
          activateSubscription: !items,
          projectIds: Array.isArray(projectId) ? projectId : [projectId],
          projectName,
          fileName: projectName,
          anchorEl,
          open: true,
        })
      },
      async onExport(contentType: string, items?: Item[], alias?: string) {
        const itemDownloads = items || state.items || []
        const itemIds = itemDownloads.map((i) => i.id)

        if (!itemIds.length) {
          return addSnack({
            message: 'Error while downloading items. Empty List.',
            severity: 'warning',
          })
        }

        setState({ creatingJobFor: contentType })

        const fileName = state.fileName || state.projectName
        const label = `${fileName.replace(' ', '_')}.${
          extension[contentType.toLocaleLowerCase()]
        } (${alias || contentType})`
        const id = label + new Date()
        try {
          const response = await api.exportItems({
            body: { export_type: contentType, item_ids: itemIds, file_name: fileName },
          })
          const jobId = response.data?.job_id

          if (jobId) {
            useExportJobs.getState().add({
              id: jobId,
              status: 'pending',
              createdAt: new Date().toISOString(),
              fileName,
              label,
              exportType: contentType,
              totalCompleted: 0,
              totalFailed: 0,
              totalItems: itemIds.length,
              errorDetails: {} as ErrorDetails,
            })
          }

          setState({ creatingJobFor: '', activateSubscription: false, anchorEl: null })
        } catch (error) {
          const { message } = ErrorHandler(error) as any
          addSnack({
            message: `Error while downloading items. ${message}`,
            severity: 'error',
          })
          useExportJobs.getState().remove(id)
          setState({ creatingJobFor: '' })
          throw error
        }
      },
    }),
    [state],
  )
}

export type DownloadMenuState = ReturnType<typeof useDownloadItems>

/**
 * Return reason if export type can't be downloaded
 * @param {*} items - Array
 * @returns reason - String
 */
export const validateDownloadItems = (items?: Item[]): ValidationResult => {
  const reason: ValidationResult = {
    comptia: { value: '' },
    surpass: { value: '', missingKeyItems: [], totalItems: items?.length || 0 },
    qti: { value: '' },
  }

  items?.forEach((item) => {
    try {
      const content = item.savedContent[0]?.content || {}

      if (item.contentType !== MCQ) {
        const error = "Only MCQ items can be downloaded as '#type'"
        reason.comptia.value = error
        reason.surpass.value = error
      }

      if (item.contentType === MCQ) {
        const hasKey = content?.answers?.some(({ status }) => status === MCQ_STATUSES.KEY)

        if (content?.answers && !hasKey) {
          reason.surpass.missingKeyItems.push({ question: content.question || '', id: item.id })
        }
      }

      if (content.passage || content.custom_passage) {
        reason.surpass.value = "Only items without a stimulus can be downloaded as 'Surpass'"
      }
      return true
    } catch (error) {
      console.error(error)
    }
  })

  return reason
}
